import jspreadsheet from 'jspreadsheet-ce';

let accountsTable = null;
let contactsTable = null;
let modificationsTable = null;

export function getAccounts() {
    return accountsTable.getData().filter(row => row.some(col => col.length > 0));
}

export function getContacts() {
    return contactsTable.getData().filter(row => row.some(col => col.length > 0));
}

export function getModifications() {
    return modificationsTable.getData().filter(row => row.some(col => col.length > 0));
}

window.onload = function () {
    const tableOptions = {
        includeHeadersOnDownload: true,
        allowInsertColumn: false,
        allowManualInsertColumn: false,
        allowRenameColumn: false,
        allowComments: false,
        contextMenu: null,
        columnResize: false,
        rowResize: false,
    };

    const accounts = JSON.parse(localStorage.getItem("accounts")) || [
        ["G...", "My Stellar Account", ""],
    ];
    const accountsTableOptions = {
        ...tableOptions,
        data: accounts,
        csvFileName: "Stellar Accounts.csv",
        colAlignments: ['left', 'left', 'left'],
        columns: [
            { type: 'text', title: 'Account', width: 560 },
            { type: 'text', title: 'Description', width: 340 },
            { type: 'text', title: 'Date Lost (YYYY-MM-DD)', width: 220 },
        ],
    };
    const accountsElement = document.getElementById("accounts");
    accountsTable = jspreadsheet(accountsElement, accountsTableOptions);
    accountsTable.onafterchanges = function () {
        localStorage.setItem('accounts', JSON.stringify(accountsTable.getData()));
    };
    accountsElement.onblur = function() {
        localStorage.setItem('accounts', JSON.stringify(accountsTable.getData()));
    };

    const contacts = JSON.parse(localStorage.getItem("contacts")) || [
        ["GCOINSKIBDB7E4YUMZVEATT7JRIWLUPH6CBS63FYIZ4DWDXH3P6U6XIU", "Exchange: Coinspot"],
        ["GBDUXW4E5WRM5EM6UXBLE7Y5XGSXJX472BSSBPKFPQ3PJCJHRIA6SH4C", "Exchange: Coinbase"],
    ];
    const contactsElement = document.getElementById("contacts");
    const contactsTableOptions = {
        ...tableOptions,
        data: contacts,
        csvFileName: "Stellar Contacts.csv",
        colAlignments: ['left', 'left'],
        columns: [
            { type: 'text', title: 'Account', width: 560 },
            { type: 'text', title: 'Description', width: 340 },
        ],
    };
    contactsTable = jspreadsheet(contactsElement, contactsTableOptions);
    contactsTable.onafterchanges = function () {
        localStorage.setItem('contacts', JSON.stringify(contactsTable.getData()));
    };
    contactsElement.onblur = function() {
        localStorage.setItem('contacts', JSON.stringify(contactsTable.getData()));
    };

    const modifications = JSON.parse(localStorage.getItem("modifications")) || [["", ""]];
    const modificationsElement = document.getElementById("modifications");
    const modificationsTableOptions = {
        ...tableOptions,
        data: modifications,
        csvFileName: "Stellar Modifications.csv",
        colAlignments: ['left', 'left'],
        columns: [
            { type: 'text', title: 'Transaction ID ({transaction hash} {operation id} {effect id})', width: 1104 },
            {
                type: 'dropdown', title: 'Map to', width: 180, source: [
                    { 'id': 'TRANSFER', 'name': 'Transfer' },
                    { 'id': 'GIFT', 'name': 'Gift' },
                    { 'id': 'TRADE', 'name': 'Trade (Buy/Sell)' },
                ]
            },
            { type: 'text', title: 'USD Value', width: 180, mask: '#0.00', options: { reverse: true } },
        ],
    };
    modificationsTable = jspreadsheet(modificationsElement, modificationsTableOptions);
    modificationsTable.onafterchanges = function () {
        localStorage.setItem('modifications', JSON.stringify(modificationsTable.getData()));
    };
    modificationsElement.onblur = function() {
        localStorage.setItem('modifications', JSON.stringify(modificationsTable.getData()));
    };
};
